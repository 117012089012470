import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotkeysService } from 'angular2-hotkeys';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ActivatedRoute, convertToParamMap } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-department-view',
  templateUrl: './department-view.component.html',
  styleUrls: ['./department-view.component.css']
})
export class DepartmentViewComponent implements OnInit {

  isDepartmentMgr: boolean = false;
  department: any;
  new: any = {
    date: '',
    start: '',
    duration: '',
    pay: 0,
    user: null,
    checkin: null,
    checkout: null,
    paid: 0
  }
  accountSearchResults: any = [];
  searching = false;
  newUser: any = {
    user: null,
    role: 0
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private hotkeysService: HotkeysService,
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.appService.getDepartment(params.get("department")).then(result => {
        this.department = result;
        this.isDepartmentMgr = this.appGlobals.user.departments.find(o => o.id === this.department.id).role >= 1;
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    });
    
  }

  addSchedule() {
    this.appService.addDepartmentSchedule(this.activatedRoute.snapshot.params.department, this.new).then(result => {
      this.department.schedule.push(result);
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  updateSchedule(item: any) {
    this.appService.updateDepartmentSchedule(this.activatedRoute.snapshot.params.department, item).then(result => {
      this.toastrService.success("saved");
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  removeSchedule(item: any) {
    if (confirm(`Are you sure you want to remove this schedule?`)) {
      this.appService.removeDepartmentSchedule(this.activatedRoute.snapshot.params.department, item.id).then(result => {
        this.toastrService.success("removed");
        this.department.schedule.splice(this.department.schedule.indexOf(item), 1);
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }
  checkin(item: any) {
    let user = this.department.users.find(o => o.id == item.user);
    if (confirm(`Are you sure you want to check-in '${user.firstname} ${user.lastname}`)) {
      item.checkin = new Date();
      this.updateSchedule(item);
    }
  }

  checkout(item: any) {
    let user = this.department.users.find(o => o.id == item.user);
    if (confirm(`Are you sure you want to check-out '${user.firstname} ${user.lastname}`)) {
      item.checkout = new Date();
      this.updateSchedule(item);
    }
  }

  pay(item: any) {
    let user = this.department.users.find(o => o.id == item.user);
    if (confirm(`Are you sure you want to pay '${user.firstname} ${user.lastname}`)) {
      this.appService.payDepartmentSchedule(this.department.id, item.id, item.pay).then(result => {
        this.toastrService.success("paid");
        item.paid = 1;
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }

  userSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.listUsers(convertToParamMap({ search: term.trim() })).pipe(
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  formatter = (x: { id: number, firstname: string, lastname: string, nickname: string, email: string }) => {
    return `${x.firstname} ${x.lastname} (${x.nickname})`
  };
  addUser() {
    this.appService.addDepartmentUser(this.department.id, this.newUser.user.id, this.newUser.role).then(result => {
      this.department.users.push(result);
      this.toastrService.success("Added");
      this.newUser.user = null;
      this.newUser.role = 0;
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }
  updateUser(user: any) {
    this.appService.updateDepartmentUser(this.department.id, user).then(result => {
      this.toastrService.success("saved");
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }
  removeUser(user: any) {
    if (confirm(`Are you sure you want to pay '${user.firstname} ${user.lastname}`)) {
      this.appService.removeDepartmentUser(this.department.id, user.id).then(result => {
        this.toastrService.success("removed");
        this.department.users.splice(this.department.users.indexOf(user), 1);
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
  }
}
