import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { FindUserDialogComponent } from '../find-user-dialog/find-user-dialog.component';
import { TransferFundsDialogComponent } from '../transfer-funds-dialog/transfer-funds-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CreateAccountDialogComponent } from '../create-account-dialog/create-account-dialog.component';
import { convertToParamMap, Router } from '@angular/router';

@Component({
  selector: 'ngbd-find-account-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Find Account</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="findAccounts()">
        <div class="form-row">
          <div class="col-6">
            <label>Account Number</label>
            <input type="text" class="form-control" placeholder="Account Number" name="id" [(ngModel)]="filter.id" autofocus>
          </div>
          <div class="col-6">
            <label>Account Name</label>
            <input type="text" class="form-control" placeholder="Account Name" name="name" [(ngModel)]="filter.name">
          </div>
        </div>
        <div class="d-flex justify-content-end pt-2">
            <button type="submit" class="btn btn-primary btn-sm">Search</button>
        </div>
      </form>
      <hr>
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Account ID</th>
              <th>Account Name</th>
              <th class="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="accounts.length == 0">
              <td colspan="6" class="text-center">No accounts found</td>
            </tr>
            <tr *ngFor="let item of accounts">
              <td>{{item.id}}</td>
              <td>{{item.name}}</td>
              <td class="text-right">
                <button class="dcs-btn dcs-btn-xs dcs-btn-select" (click)="activeModal.close(item)" title="Select Account"></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="activeModal.dismiss('Close click')">Close</button>
    </div>
  `
})
export class FindAccountDialogComponent {
  filter: any = {};
  accounts: any = [];

  constructor(public activeModal: NgbActiveModal,
    private toastrService: ToastrService, 
    private appService: AppService
  ) {}

  findAccounts() {
    this.appService.getAccounts(convertToParamMap(this.filter))
      .toPromise()
      .then(results => this.accounts = results)
      .catch(err => this.toastrService.error(err.error || err.statusText || err));
  }
}

@Component({
  selector: 'app-teller-dashboard',
  templateUrl: './teller-dashboard.component.html',
  styleUrls: ['./teller-dashboard.component.css']
})
export class TellerDashboardComponent implements OnInit {

  user: any = null;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private hotkeysService: HotkeysService,
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { 
    this.hotkeysService.add(new Hotkey('f1', (event: KeyboardEvent): boolean => {
      this.newSession();
      return false;
    }));
    this.hotkeysService.add(new Hotkey('esc', (event: KeyboardEvent): boolean => {
      this.user = null;
      return false;
    }));
  }

  ngOnInit() {
  }

  newSession() {
    const modalRef = this.modalService.open(FindUserDialogComponent, { size: 'lg', backdrop: false });
    modalRef.result.then(user => {
      this.appService.getUser(user.id).then(result => {
        this.user = result;
      }).catch(err => {
        console.error(err)
      });
    }).catch(err => {
      console.error(err)
    });
  }

  openTransferWindow(account) {
    const modalRef = this.modalService.open(TransferFundsDialogComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.transfer.from = account.id;
    modalRef.result.then(transfer => {
      this.appService.transferFunds(this.user.id, transfer.from, transfer.to, transfer.amount).then(result => {
        this.toastrService.success('Transfer Complete');
        if (this.user.accounts.find(o => o.id == transfer.from) != undefined) {
          this.user.accounts.find(o => o.id == transfer.from).balance -= parseInt(transfer.amount || "0");
        }
        if (this.user.accounts.find(o => o.id == transfer.to) != undefined) {
          this.user.accounts.find(o => o.id == transfer.to).balance += parseInt(transfer.amount || "0");
        }
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }).catch(err => {
      console.error(err)
    });
  }

  createAccount() {
    const modalRef = this.modalService.open(CreateAccountDialogComponent, { size: 'lg' });
    modalRef.result.then(data => {
      this.appService.createUserAccount(this.user.id, data).then(account => {
        this.toastrService.success('Account Created');
        this.user.accounts.push(account);
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }).catch(err => {
      console.error(err)
    });
  }

  linkAccount() {
    const modalRef = this.modalService.open(FindAccountDialogComponent, { size: 'lg' });
    modalRef.result.then(account => {
      this.appService.addUserAccount(this.user.id, account.id).then(account => {
        this.toastrService.success('Account Linked');
        this.user.accounts.push(account);
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }).catch(err => {
      console.error(err)
    });
  }

  removeAccount(item:any) {
    if (!confirm("Are you sure you want to remove this account from this user?"))
      return;
    this.appService.removeUserAccount(this.user.id, item.id)
      .then(() => this.user.accounts.splice(this.user.accounts.indexOf(item),1))
      .then(() => this.toastrService.success('Account Removed'))
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  createUser() {
    this.appService.createUser(this.user).then(result => {
      this.toastrService.success('User Created');
      this.appService.getUser(result.id).then(result => {
        this.user = result;
      }).catch(err => this.toastrService.error(err.error || err.statusText));
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  updateUser() {
    this.appService.updateUser(this.user).then(() => {
      this.toastrService.success('User Updated');
      this.user.modified = new Date();
    }).catch(err => this.toastrService.error(err.error || err.statusText));
  }

  showTransactions(item: Account) {
    this.appService.getAccount(item.id)
    .then(result => {
      const modalRef = this.modalService.open(DCSTransactionModal, { size: 'lg' });
      modalRef.componentInstance.account = result;
      return modalRef.result.then(() => {}, () => {});
    })
    .catch(err => this.toastrService.error(err.error || err.statusText || err));
  }
}


@Component({
  selector: 'dcs-transaction-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Transactions</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive-sm">
        <table class="table table-hover table-sm">
            <thead>
                <tr class="row">
                    <th class="col-sm-3">Date/Time</th>
                    <th class="col-sm-5">Description</th>
                    <th class="col-sm-2">Amount</th>
                    <th class="col-sm-2">Balance</th>
                </tr>
            </thead>
            <tbody>
                <tr class="row" *ngFor="let item of account.transactions">
                    <td class="col-sm-3">{{item.created | amLocal | amDateFormat: 'MM/DD/YY HH:mm'}}</td>
                    <td class="col-sm-5">{{item.description}}</td>
                    <td class="col-sm-2" *ngIf="item.deposit != null">{{item.deposit}} &#8353;</td>
                    <td class="col-sm-2" *ngIf="item.withdrawal != null">-{{item.withdrawal}} &#8353;</td>
                    <td class="col-sm-2">{{item.balance}} &#8353;</td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `
})
export class DCSTransactionModal {
  @Input() account;
  constructor(public activeModal: NgbActiveModal) {}
}
