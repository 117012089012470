import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  user: any = {
    firstname: '',
    lastname: '',
    nickname: '',
    email: '',
    password: '',
    confirmpassword: ''
  };

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  createUser() {
    if (this.user.password != this.user.confirmpassword) { return alert('Passwords do not match!'); }

    this.appService.createUser(this.user)
    .then(result => this.appService.login(this.user.email, this.user.password))
    .then(result => {
      this.router.navigate(['/dashboard']);
    })
    .catch((err) => alert(err));
  }

}
