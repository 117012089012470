import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';
import { convertToParamMap } from '@angular/router';

@Component({
  selector: 'app-find-user-dialog',
  templateUrl: './find-user-dialog.component.html',
  styleUrls: ['./find-user-dialog.component.css']
})
export class FindUserDialogComponent implements OnInit {

  filter: any = {};
  users: Array<any> = new Array<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private appService: AppService
  ) { }

  ngOnInit() {
    document.getElementsByName('search')[0].focus();
  }

  findUsers() {
    this.appService.listUsers(convertToParamMap(this.filter)).toPromise().then(results => {
      this.users = results;
    }).catch(err => {
      console.error(err)
    });
  }

  doSearchKeyUp(evnt) {
    if (evnt.keyCode == 13)
      this.findUsers()
  }
}
