import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { convertToParamMap } from '@angular/router';

@Component({
  selector: 'app-superadmin-dashboard',
  templateUrl: './superadmin-dashboard.component.html',
  styleUrls: ['./superadmin-dashboard.component.css']
})
export class SuperadminDashboardComponent implements OnInit {
  accountSearchResults: any = [];
  searching = false;
  accounts: any = [];
  lowbalance: any = [];


  cur: any = {
    user: null,
    role: 0
  }
  passwordChange: any = { user: null, password: '' }
  userDelete: any = null

  stats:any = {}

  ncausers: any = [];

  constructor(
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.appService.listUsers(convertToParamMap({role: "96;97;98;99"}))
      .toPromise()
      .then(results => this.ncausers = results)
      .catch(err => this.toastrService.error(err.error || err.statusText));
    this.appService.getStats()
      .then(results => this.stats = results)
      .catch(err => this.toastrService.error(err.error || err.statusText));
    this.appService.listAccounts(convertToParamMap({id: "<3000"}))
      .then(results => this.accounts = results)
      .catch(err => this.toastrService.error(err.error || err.statusText));
    this.appService.listAccounts(convertToParamMap({balance: "<0"}))
      .then(results => this.lowbalance = results)
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  accountSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.listUsers(convertToParamMap({ search: term.trim() })).toPromise().then(results => {
          return results;
        }).catch(err => {
          return [];
        })
      ),
      tap(() => this.searching = false)
    )

  formatter = (x: { id: number, firstname: string, lastname: string, nickname: string }) => x.firstname + ' ' + x.lastname + ' (' + x.nickname + ')';

  changeUserRole() {
    this.appService.getUser(this.cur.user.id)
      .then(user => {
        user.role = this.cur.role;
        this.appService.updateUser(user)
          .then(result => {
            this.toastrService.success('The user has been updated.');
            if (this.cur.role > 0) { this.ncausers.push(user); }
          })
          .catch(err => this.toastrService.error(err.error || err.statusText));
      })
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  revokeAccess(item) {
    this.appService.getUser(item.id)
      .then(user => {
        user.role = 0;
        return this.appService.updateUser(user);
      })
      .then(result => {
        this.toastrService.success('Access Revoked!');
        this.ncausers.splice(this.ncausers.indexOf(item), 1);
      })
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  changeUserPassword() {
    this.appService.getUser(this.passwordChange.user.id)
      .then(user => {
        user.password = this.passwordChange.password;
        return this.appService.updateUser(user);
      })
      .then(result => {
        this.toastrService.success('The password has been changed.');
      })
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  async deleteUser() {
    try {
      let user = await this.appService.getUser(this.userDelete.id)
      if (user == undefined)
        throw new Error("Not Found!")
      if (!confirm("Are you sure you want to delete: " + user.firstname + " " + user.lastname + " (" + user.id + ")????"))
        return;
      await this.appService.deleteUser(this.userDelete.id)
      this.toastrService.success('The User has been deleted!');
    } catch (err) {
      this.toastrService.error(err.error || err.statusText)
    }
  }

  updateTokenExpires() {
    this.appService.updateBank({
      tokenExpireIn: this.appGlobals.bank.tokenExpireIn
    })
      .then(result => this.toastrService.success('Saved!'))
      .catch(err => this.toastrService.error(err.error || err.statusText));
  }
}
