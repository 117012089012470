import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';

@Component({
  selector: 'app-create-account-dialog',
  templateUrl: './create-account-dialog.component.html',
  styleUrls: ['./create-account-dialog.component.css']
})
export class CreateAccountDialogComponent implements OnInit {

  public account = {
    name: 'New Account',
    note: '',
    type: '3'
  }
  constructor(
    public activeModal: NgbActiveModal,
    private appService: AppService,
    public appGlobals: AppGlobals
  ) { }

  ngOnInit() {
  }

}
