import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { convertToParamMap } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  accountSearchResults: any = [];
  searching = false;
  ledger: any = [];
  ota: any = { to: null, role: 0 }
  ledgerFilter: any = {date: '', account: '', amount: '', createdBy: { id: ''}};

  constructor(
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.ledgerFilter = {
      date: '-7', 
      account: '', 
      amount: '', 
      createdBy: this.appGlobals.user
    };
    this.filterLedger();
  }

  filterLedger() {
    this.appService.getLedger(convertToParamMap({ 
      startdate: `${moment().add(this.ledgerFilter.date || -30,'days').utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss.000')}Z`,
      account: `${this.ledgerFilter.account || ''}`,
      amount: `${this.ledgerFilter.amount || ''}`,
      createdBy: `${this.ledgerFilter.createdBy.id || ''}`
    }))
      .then(result => this.ledger = result)
      .catch(err => this.toastrService.error(err.error || err.statusText || err));
  }

  accountSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this.appService.listUsers(convertToParamMap({ search: term.trim() })).toPromise().then(results => {
          return results;
        }).catch(err => {
          return [];
        })
      ),
      tap(() => this.searching = false)
    )

  formatter = (x: { id: number, firstname: string, lastname: string, nickname: string }) => x.firstname + ' ' + x.lastname + ' (' + x.nickname + ')';

  grantOTA() {
    this.appService.grantAccountOTA(this.ota.to.id, this.ota.role)
    .then(result => {
      this.toastrService.success('The user has been granted access. Grant will expire in 5 minutes.');
    })
    .catch(err => this.toastrService.error(err.error || err.statusText));
  }
}
