import { Component, OnInit } from '@angular/core';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.css']
})
export class AccountDetailComponent implements OnInit {

  account: any = {};

  constructor(
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.appService.getAccount(this.activatedRoute.snapshot.paramMap.get('account'))
    .then(results => this.account = results)
    .catch(err => this.toastrService.error(err.error || err.statusText));
  }

}
