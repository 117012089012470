import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserCreateComponent } from './user-create/user-create.component';
import { LoginComponent } from './login/login.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { TellerDashboardComponent } from './teller-dashboard/teller-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SuperadminDashboardComponent } from './superadmin-dashboard/superadmin-dashboard.component';
import { UserGuard } from './user.guard';
import { TellerGuard } from './teller.guard';
import { AdminGuard } from './admin.guard';
import { SuperadminGuard } from './superadmin.guard';
import { NotfoundComponent } from './notfound/notfound.component';
import { HomeComponent } from './home/home.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { DepartmentViewComponent } from './department-view/department-view.component';
import { DepartmentGuard } from './department.guard';
import { CampingViewComponent } from './camping-view/camping-view.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: 'signup', component: UserCreateComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset', component: ResetPasswordComponent, canActivate: [UserGuard] },
  { path: 'dashboard', component: UserDashboardComponent, canActivate: [UserGuard] },
  { path: 'teller', component: TellerDashboardComponent, canActivate: [TellerGuard] },
  { path: 'admin', component: AdminDashboardComponent, canActivate: [AdminGuard] },
  { path: 'superadmin', component: SuperadminDashboardComponent, canActivate: [SuperadminGuard] },
  { path: 'account/:account', component: AccountDetailComponent, canActivate: [UserGuard] },
  { path: 'department/:department', component: DepartmentViewComponent, canActivate: [DepartmentGuard] },
  { path: 'camping', component: CampingViewComponent, canActivate: [UserGuard] },
  { path: '', component: HomeComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
