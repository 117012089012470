import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotkeysService } from 'angular2-hotkeys';
import { ToastrService } from 'ngx-toastr';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ActivatedRoute, convertToParamMap } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-camping-view',
  templateUrl: './camping-view.component.html',
  styleUrls: ['./camping-view.component.css']
})
export class CampingViewComponent implements OnInit {

    purchased: any = [];
    available: any = [];
    accounts: any = [];
    item: any = { location: '', owner: '', account: '' };

    accountSearchResults: any = [];
    searching = false;
  
    constructor(
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        public appGlobals: AppGlobals,
        private appService: AppService
    ) { }
    
    ngOnInit() {
        this.appService.listAvailableCamping().then(result => {
            this.available = result;
            return this.appService.listUserCamping(this.appGlobals.user.id);
        }).then(result => {
            return Promise.all(result.map(o => this.appService.getCamping(o.id)));
        }).then(result => {
            this.purchased = result;
            return this.appService.getUser(this.appGlobals.user.id);
        }).then(results => {
              this.accounts = results.accounts;
              this.item.account = this.accounts[0].id;
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }

    accountSearch = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 3),
      tap(() => this.searching = true),
      switchMap(term => 
        this.appService.listUsers(convertToParamMap({search: term.trim()})).pipe(
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

    formatter = (x: {id: number, nickname: string}) => x.nickname;

    purchase() {
        this.appService.purchaseCamping(this.item.location, this.item.owner, this.item.account).then(result => {
            this.purchased.push(result);
            this.item = { location: '', owner: '', account: this.accounts[0].id };
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }

    addMember(item:any) {
        this.appService.addUserToCamping(item.id, item.newMember.id).then(result => {
            item.members.push(result);
            item.newMember = null;
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }

    removeMember(item:any, member:any) {
        this.appService.removeUserFromCamping(item.id, member.id).then(result => {
            item.members.splice(item.members.indexOf(member), 1);
        }).catch(err => this.toastrService.error(err.error || err.statusText));
    }
}