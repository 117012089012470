import { Component, OnInit } from '@angular/core';
import { AppGlobals } from '../app.globals';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, switchMap, catchError, tap, timeout } from 'rxjs/operators';
import { convertToParamMap } from '@angular/router';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  accounts: any = [];
  transfer: any = {
    from: null,
    to: null,
    amount: 0
  }
  showTransferComplete: boolean = false;

  //accountSearch: Subject<string> = new Subject<string>();
  accountSearchResults: any = [];
  searching = false;

  constructor(
    private toastrService: ToastrService,
    public appGlobals: AppGlobals,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.appService.getUser(this.appGlobals.user.id)
    .then(results => {
      this.accounts = results.accounts;
      this.transfer.from = this.accounts[0].id;
    })
    .catch(err => alert(err));

    /*this.accountSearch
    .pipe(debounceTime(1000))
    .pipe(distinctUntilChanged())
    //.pipe(filter((query: string) => query.length >= 2))
    .subscribe(text => {
      this.appService.getAccounts(convertToParamMap({search: text}))
      .then(results => {
        console.log(results);
        this.accountSearchResults = results;
      })
    });*/
  }

  accountSearch = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(o => o.trim().length >= 3),
      tap(() => this.searching = true),
      switchMap(term => 
        this.appService.getAccounts(convertToParamMap({search: term.trim()})).pipe(
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

    formatter = (x: {id: number, name: string}) => x.name;

  transferFunds() {
    this.appService.transferFunds(this.appGlobals.user.id, this.transfer.from, this.transfer.to.id, this.transfer.amount)
    .then(result => {
      this.accounts.find(o => o.id == this.transfer.from).balance -= parseInt(this.transfer.amount);
      if (this.accounts.find(o => o.id == this.transfer.to.id) != undefined) {
        this.accounts.find(o => o.id == this.transfer.to.id).balance += parseInt(this.transfer.amount);
      }
      this.transfer.amount = 0; // = { from: this.accounts[0].id, to: null, amount: 0 };

      this.toastrService.success("Transfer Complete");
      this.showTransferComplete = true;
      setTimeout(function() {
        this.showTransferComplete = false;
      }.bind(this), 5000);
    })
    .catch(err => this.toastrService.error(err.error || err.statusText));
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
