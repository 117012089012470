import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transfer-funds-dialog',
  templateUrl: './transfer-funds-dialog.component.html',
  styleUrls: ['./transfer-funds-dialog.component.css']
})
export class TransferFundsDialogComponent implements OnInit {

  public transfer = {
    from: null,
    to: null,
    amount: null
  }

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
